.highcharts-tooltip {
  box-shadow: 0 0 14px 0 rgba(23, 0, 73, 0.21);
}

.highcharts-tooltip > span:before {
  content: "";
  display: block;
  width: calc(100% + 26px);
  height: calc(100% + 26px);
  box-shadow: 0 0 14px 0 rgba(23, 0, 73, 0.21);
  position: absolute;
  left: -13px;
  top: -13px;
}

.general-info .highcharts-tooltip > span:before {
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  left: -8px;
  top: -8px;
}
