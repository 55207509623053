.ps__thumb-y {
  right: 8px !important;
  background-color: #d1d7e9 !important;
  width: 6px !important;
}

.ps__rail-y {
  background-color: rgba(0, 0, 0, 0) !important;
}

.chat-box .ps__thumb-y,
.message-box .ps__thumb-y {
  right: 0px !important;
  background-color: #d8d8d8 !important;
  width: 8px !important;
  border-radius: 4px !important;
}

.recommend-modal .ps__thumb-y {
  left: 30px !important;
  width: 8px !important;
  background-color: #d8d8d8 !important;
  border-radius: 4px !important;
}
